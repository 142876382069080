import React from 'react';
import styles from '../Errors.module.scss';

export const NotFoundError = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                <div className={`card bg-light ${styles.card}`}>
                    <div className="card-header text-danger">404</div>
                    <div className="card-body">
                        <p className="card-text">Required page not found.</p>
                        <button className={`btn btn-outline-secondary btn-large ${styles.btn}`}>
                            <a
                                className={styles.homepage}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://accenture-acp-dev-admin.oktapreview.com/admin/dashboard/"
                            >
                                Go to Homepage
                            </a>
                        </button>
                    </div>
                    <footer className={styles.footer}>
                        <a className={styles.footerLink} href="https://icm.accenture.com/public/terms-of-use/">
                            Terms of use
                        </a>
                        <br />
                        <small>©2020 Accenture All Rights Reserved</small>
                    </footer>
                </div>
            </div>
        </div>
    );
};
